<template>
  <ErrorComp v-if="mostrarError" />
  <div v-else>
    <div class="container">
      <div class="imagen">
        <div class="logo-container">
          <img src="@/assets/person-circle.png" alt="Imagen" class="logo" />
        </div>
      </div>
      <div class="titulo">Necesitamos sus datos personales</div>
      <div>
        <input
          class="phone"
          type="text"
          v-model="phone"
          placeholder="   Ingresá tu número de teléfono"
          maxlength="10"
          @input="PhoneInput"
        />
      </div>

      <div>
        <input
          class="full_name"
          type="text"
          v-model="full_name"
          placeholder="   Ingresá tu nombre completo"
        />
      </div>

      <div class="select-container">
        <select
          id="user_type"
          v-model="user_type"
          class="user-type-select phone"
        >
          <option value="usuario">Cliente</option>
          <option value="Administrador">Administrador</option>
          <option value="Trabajador">Trabajador</option>
        </select>
      </div>

      <button class="button" @click="Guardar">Crear cuenta</button>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import ErrorComp from "@/components/ErrorComp";
import CryptoJS from 'crypto-js';
export default {
  name: "RegistrarComp",
  components: {
    ErrorComp,
  },
  data() {
    return {
      mostrarError: false,
      phone: "",
      full_name: "",
      user_type: "usuario",
      password: "usuario1",
    };
  },
  mounted() {
    this.ComprobarAPi();
    this.token = localStorage.getItem("Token");
  },
  methods: {
    PhoneInput(){
      this.phone = this.phone.replace(/\D/g, '');
    },
    ComprobarAPi() {
      axios
        .post(`${this.$ruta.value}/login`)
        .then(() => {
          console.log("API está funcionando correctamente");
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            console.error("Error de validación:", error.response.data);
          } else {
            this.mostrarError = true;
            console.error("Error en la solicitud:", error);
          }
        });
    },

    Guardar() {
      const token = localStorage.getItem("Token");
      const bytes = CryptoJS.AES.decrypt(token, this.$claveSecreta.value);
      const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);
      //console.log(tokenDescifrado);
      const config = {
        headers: {
          Authorization: `Bearer ${tokenDescifrado}`,
        },
      };

      axios
        .post(
          `${this.$ruta.value}/register`,
          {
            full_name: this.full_name,
            user_type: this.user_type,
            phone: this.phone,
            password: this.password,
          },
          config
        )
        .then(router.push("/crearcuenta"))
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  },
};
</script>

<style scoped>
.imagen {
  width: 20%;
  height: auto;
  top: 5%;
  left: 35%;
  position: absolute;
}

.logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 100%;
  height: auto;
  margin-left: 50%;
}

.container {
  width: 30%;
  height: 70%;
  position: absolute;
  top: 55%;
  left: 55%;
  transform: translate(-50%, -50%);
  background-color: #171b1e;
  border-radius: 15px;
}

.user-type-select,
.phone {
  width: 70%;
  height: 8%;
  top: 46%;
  left: 50%;
  transform: translate(-49%, -10%);
  position: absolute;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 15px;
  color: #ffffff;
  text-align: left;
  background-color: #3d3d3d;
  line-height: 20px;
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid #3d3d3d;
}

.full_name {
  width: 70%;
  height: 8%;
  top: 57%;
  left: 50%;
  transform: translate(-49%, -10%);
  position: absolute;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 15px;
  color: #ffffff;
  background-color: #3d3d3d;
  text-align: left;
  line-height: 20px;
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid #3d3d3d;
  margin-top: 1%;
}

.user-type-select {
  width: 71%;
  height: 9%;
  top: 67%;
  /* Adjust the positioning as needed */
  left: 50%;
  transform: translate(-49%, -10%);
  position: absolute;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 15px;
  color: #ffffff;
  background-color: #3d3d3d;
  text-align: left;
  line-height: 20px;
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid #3d3d3d;
  margin-top: 3%;
  padding-left: 2%;
}

.full_name::placeholder {
  color: #ffffff;
}

.phone::placeholder {
  color: #ffffff;
}

.button {
  width: 73%;
  height: 9%;
  top: 85%;
  left: 50%;
  transform: translate(-49%, -10%);
  position: absolute;
  background-color: #41bf49;
  border-radius: 10px;
  text-align: center;
  color: white;
  font-family: "Poppins-SemiBold", Helvetica;
  font-weight: 600;
  font-size: 16px;
  border-color: transparent;
}

.titulo {
  width: 60%;
  height: 10%;
  top: 25%;
  left: 40%;
  transform: translate(-46%, -10%);
  position: absolute;
  font-family: "Poppins-SemiBold", Helvetica;
  font-size: 25px;
  color: #41bf49;
  text-align: center;
  line-height: 20px;
  font-weight: 600;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .container {
    width: 80%;
  }

  .imagen {
    width: 30%;
  }

  .titulo {
    width: 80%;
    left: 40%;
  }

  .input-style {
    width: 90%;
  }

  .button {
    width: 93%;
  }
}
</style>
