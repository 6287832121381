<template>
  <div class="perfiClientes">
    <div v-if="loading" class="spinner-container">
      <i class="fas fa-circle-notch fa-spin" style="font-size: 2em; color: #5e72e4; margin-right: 8px"></i>
      <Spinner :size="{ width: '4em', height: '4em' }" :color="'#5e72e4'" class="style-spinner" />
    </div>
    <div v-else>
      <input type="file" accept="image/*" id="img" style="display: none;" ref="img" @change="actImg" />
      <label for="img"><img for: class="usuCliente" :src="datosUser.image" /></label>

      <div class="hiCliente">¡Hola {{ this.first_name }}!</div>
      <div class="nomCliente">Nombre: {{ datosUser.full_name }}</div>
      <div class="celCliente">Teléfono: {{ datosUser.phone }}</div>
      <button @click="contraseña" class="cambiarContra">
        Cambiar contraseña
      </button>
      <button @click="cerrarSesion" class="exit">
        <span class="material-symbols-outlined"> logout </span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CryptoJS from "crypto-js";
import router from "@/router";
import { Spinner } from "vue-spinners";
export default {
  name: "PerfilComp",
  components: {
    Spinner,
  },
  data() {
    return {
      datosUser: "",
      id: localStorage.getItem("id"),
      token: localStorage.getItem("Token"),
      first_name: "",
      img: null,
      loading: true,
    };
  },
  mounted() {

    const bytes = CryptoJS.AES.decrypt(this.token, this.$claveSecreta.value);
    const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);

    const config = {
      headers: {
        Authorization: `Bearer ${tokenDescifrado}`,
      },
    };
    axios
      .get(`${this.$ruta.value}/show/${this.id}`, config)
      .then((response) => {
        this.datosUser = response.data;
        this.first_name = this.datosUser.full_name.split(" ")[0];
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          console.error("Error al enviar el mensaje:", error);
        }
      })

      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    contraseña() {
      router.push("/contrasenaUser");
    },
    async actImg(event) {
      const file = event.target.files[0];
      if (file) {
        let formData = new FormData();
        formData.append('image', file);

        const bytes = CryptoJS.AES.decrypt(
          this.token,
          this.$claveSecreta.value
        );
        const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);
        const config = {
          headers: {
            Authorization: `Bearer ${tokenDescifrado}`,
          },
        };

        try {
          await axios.post(
            `${this.$ruta.value}/editImage/${this.id}`,
            formData, config)
        } catch (error) {
          console.log(error);
        }
        window.location.reload();
      }
    },
    cerrarSesion() {
      const token = localStorage.getItem("Token");
      const bytes = CryptoJS.AES.decrypt(token, this.$claveSecreta.value);
      const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);
      const config = {
        headers: {
          Authorization: `Bearer ${tokenDescifrado}`,
        },
      };
      axios
        .get(`${this.$ruta.value}/logout`, config)
        .then((response) => {
          console.log(response.data);
          localStorage.setItem("Token", null);
          localStorage.setItem("logged", false);
          localStorage.setItem("id", null);
          localStorage.setItem("userType", null);
          this.$userType.value = "null";
          router.push("/login");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style>
@media screen and (max-width: 768px) {
  .perfiClientes {
    width: 80%;
    height: 60%;
    top: 17vh;
    margin-left: 50%;
    transform: translateX(-50%);
    background-color: #171b1e;
    border-radius: 15px;
    border-color: transparent;
    position: absolute;
    justify-content: center;
  }

  .usuCliente {
    height: 11vh;
    width: 22vw;
    position: absolute;
    top: 8%;
    border-radius: 50%;
    left: 38%;
    align-items: center;
    justify-content: center;
    display: flex;
    object-fit: cover;
  }

  .nomCliente {
    color: white;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 17px;
    font-weight: 500;
    left: 20%;
    line-height: normal;
    position: fixed;
    text-align: center;
    top: 47%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .celCliente {
    color: white;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 17px;
    font-weight: 500;
    left: 20%;
    letter-spacing: 0;
    line-height: normal;
    position: fixed;
    text-align: center;
    top: 63%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .hiCliente {
    color: #41bf49;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 21px;
    font-weight: 600;
    left: 0;
    right: 0;
    letter-spacing: 0;
    line-height: normal;
    position: fixed;
    text-align: center;
    top: 32%;
  }

  .cambiarContra {
    background-color: #41bf49;
    font-size: 13px;
    height: 5vh;
    width: 55vw;
    left: 16%;
    position: absolute;
    color: white;
    border-radius: 10px;
    padding-left: 3%;
    text-align: center;
    top: 72%;
    font-family: "Poppins-SemiBold", Helvetica;
    font-weight: 600;
    border-color: transparent;
  }

  .exit {
    background-color: #ce3232;
    height: 5vh;
    width: 7vw;
    left: 44%;
    position: absolute;
    color: white;
    border-radius: 50%;
    padding-left: 3%;
    text-align: center;
    top: 85%;
    font-family: "Poppins-SemiBold", Helvetica;
    font-weight: 600;
    border-color: transparent;
    line-height: normal;
    width: fit-content;
    text-decoration: none;
    border-bottom: none;
    display: flex;
    align-items: center;
  }

  .material-symbols-outlined {
    font-size: 22px;
  }

  .style-spinner {
    width: 50px;
    height: 50px;
    border: 4px solid transparent;
    border-top: 4px solid #41bf49;
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
