<template>
    <div class="contenedorSaldo">
        <div class="saldo">
            <div class="disponibleSaldo">Dinero disponible</div>
            <div class="cantidadSaldo">$ {{ cantidad }}</div>
            <div class="expira">Expira el {{ due_date }}</div>
        </div>
       <button @click="ingresar" class="ingresar">Ingresar dinero</button>
       <button @click="retirar" class="retirar">Retirar dinero</button>
    </div>
</template>

<script>
import router from '@/router';
import moment from 'moment';
import 'moment/locale/es';
export default {
    name: 'SaldoComp',
    data(){
        return{
            cantidad: "",
            due_date:""
        }
    },
    methods : {
        ingresar(){
            router.push("/nuevatransaccion");
        },
        retirar(){
            if (this.cantidad!='0') {
                router.push("/nuevoretiro");    
            }
        }
    },
    mounted (){
        this.cantidad=this.$saldoSelect.value;
        this.due_date=this.$dueDateSelect.value;
        var fechaMoment = moment (this.due_date, 'YYYY-MM-DD');
        this.due_date = fechaMoment.format ('LL');
    }
};
</script>

<style>

.contenedorSaldo{
    position: absolute;
    background-color: transparent;
    height: 38vh;
    width: 35vw;
    top: 15%;
    left: 17%;
}
.saldo {
    background-color: #171B1E;
    border-radius: 18px;
    height: 27vh;
    width: 35vw;
    overflow: hidden;
    position: absolute;
}

.disponibleSaldo {
    color: white;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 30px;
    font-weight: 400;
    left: 32px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 35px;
    white-space: nowrap;
    width: 7vh;
}

.cantidadSaldo {
    color: white;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 45px;
    font-weight: 400;
    left: 32px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 85px;
}

.expira {
    color: white;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 17px;
    font-weight: 400;
    left: 32px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 160px;
}

.ingresar{
    background-color: #41bf49;
    border-radius: 15px;
    height: 7vh;
    overflow: hidden;
    position: absolute;
    width: 16vw;
    color: white;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    top: 80%;
    left: 1%;
    border-color: transparent;
    
}

.retirar{
    background-color: #ce3232;
    border-radius: 15px;
    height: 7vh;
    overflow: hidden;
    position: absolute;
    width: 16vw;
    color: white;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    top: 80%;
    left: 54%;
    border-color: transparent;
    
}
</style>