<template>
    <div class="contExito">
        <div class="listoRetiro">
            ¡Listo! <br>
            {{mensaje}}
        </div>
        
        <div class="circulo">
            <img class="festejo" src="@/assets/festejo.png">
        </div>
        <button @click="volver" class="volverInicio">
            Volver al inicio
        </button>
    </div>
</template>

<script>
import router from '@/router';

export default {
    props:['mensaje']
    ,
    methods: {
        volver() {
            router.push('/inicio');
            this.$tipoOp.value="null";
        },
    }
}
</script>

<style>
.contExito {
    width: 45vw;
    height: 65vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #171B1E;
    border-radius: 10px;
}

.listoRetiro {
    color: #41bf49;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 30px;
    font-weight: 600;
    left: 20%;
    letter-spacing: 0;
    line-height: normal;
    position: fixed;
    text-align: center;
    top: 10%;

}

.listoIngreso {
    color: #41bf49;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 30px;
    font-weight: 600;
    left: 20%;
    letter-spacing: 0;
    line-height: normal;
    position: fixed;
    text-align: center;
    top: 10%;

}


.circulo {
    height: 24vh;
    width: 13vw;
    position: absolute;
    top: 33%;
    border-radius: 50%;
    background-color: #2e2e2e;
    left: 35%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.volverInicio {
    background-color: #41BF49;
    font-size: 20px;
    height: 6vh;
    width: 40vw;
    left: 5%;
    position: absolute;
    color: white;
    border-radius: 10px;
    padding-left: 3%;
    text-align: center;
    top: 81%;
    font-family: "Poppins-SemiBold", Helvetica;
    font-weight: 600;
    border-color: transparent;
}

.festejo {
    width: 90%;
    height: 90%;
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    margin-top: 0%;

}
</style>