<template>
  <div class="iniClientes">
    <div v-if="loadingData && loadingAct" class="spinner-container">
      <i class="fas fa-circle-notch fa-spin" style="font-size: 2em; color: #5e72e4; margin-right: 8px"></i>
      <Spinner :size="{ width: '4em', height: '4em' }" :color="'#5e72e4'" class="style-spinner" />
    </div>
    <div v-else>
      <img class="imgCard" src="@/assets/card1.png" />
      <img class="imgMitad" src="@/assets/logoMitad.png" />
      <div class="claveCard">{{ datosUser.phone }}</div>
      <div class="lemaCard">Recompensando tu lealtad.</div>
      <div class="nombreCard">{{datosUser.full_name}}</div>
      <div class="conteClientes">
        <div class="saldoClientes">
          Saldo disponible: ${{ datosUser.account_amount }}
        </div>
        <div class="expiraClientes">Expira el {{datosUser.due_date}}</div>
        <div class="actClientes">Actividad</div>
      </div>
      <div class="ActividadPosition">
        <Actividad v-for="transaction in transactions" :key="transaction.id"
          :descripcion="transaction.description"
          :cantidad="transaction.amount"
          :tipo="transaction.transaction_type"
          :fecha="transaction.creation_date"></Actividad>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import 'moment/locale/es';
import axios from "axios";
import CryptoJS from "crypto-js";
import { Spinner } from "vue-spinners";
import Actividad from "./ActividadCliente.vue";
export default {
  name: "HomeClientes",
  components: {
    Spinner,
    Actividad
  },
  data() {
    return {
      datosUser: "",
      id: localStorage.getItem("id"),
      loadingData: true,
      loadingAct: true,
      transactions:''
    };
  },
  mounted() {
    const bytes = CryptoJS.AES.decrypt(
      localStorage.getItem("Token"),
      this.$claveSecreta.value
    );
    const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);

    const config = {
      headers: {
        Authorization: `Bearer ${tokenDescifrado}`,
      },
    };

    axios
      .get(`${this.$ruta.value}/show/${this.id}`, config)
      .then((response) => {
        this.datosUser = response.data;
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          console.error("Error al enviar el mensaje:", error);
        } else {
          this.mostrarError = true;
        }
      })

      .finally(() => {
        var due_date=this.datosUser.due_date;
        var fechaMoment = moment (due_date, 'YYYY-MM-DD');
        this.datosUser.due_date = fechaMoment.format ('LL');
        this.loadingData = false;
      });

      axios
      .get(`${this.$ruta.value}/transaction/${this.id}`, config)
      .then((response) => {
        this.transactions = response.data;
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          console.error("Error al enviar el mensaje:", error);
        } else {
          this.mostrarError = true;
        }
      })

      .finally(() => {
        this.loadingData = false;
      });
  },
};
</script>
<style>
@media screen and (max-width: 768px) {
  .iniClientes {
    width: 85%;
    height: 69%;
    top: 16vh;
    margin-left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    border-radius: 15px;
    border-color: transparent;
    position: absolute;
  }

  .imgCard {
    height: 35%;
    margin-left: 6.3%;
    position: absolute;
    top: 20%;
    transform: translateY(-50%);
    width: 75vw;
  }

  .imgMitad {
    height: 34%;
    margin-left: 64%;
    position: absolute;
    top: 19.8%;
    transform: translateY(-50%);
    width: 25vw;
    border-radius: 15px;
  }

  .saldoClientes {
    color: white;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-top: 1.5%;
  }

  .claveCard {
    color: white;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 18px;
    font-weight: 530;
    text-align: center;
    margin-top: 26%;
    margin-left: 15%;
    position: absolute;
  }

  .lemaCard {
    color: white;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 12px;
    font-weight: 530;
    text-align: center;
    margin-top: 46%;
    margin-left: 15%;
    position: absolute;
  }

  .nombreCard {
    color: white;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 12px;
    font-weight: 530;
    text-align: center;
    margin-top: 34%;
    margin-left: 15%;
    position: absolute;
  }

  .expiraClientes {
    color: white;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    margin-top: 2%;
  }

  .conteClientes {
    width: 100%;
    height: 55%;
    top: 29vh;
    margin-left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    border-radius: 15px;
    border-color: transparent;
    position: absolute;
  }

  .actClientes {
    width: 95%;
    height: 67%;
    top: 10vh;
    margin-left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    border-radius: 10px;
    border-color: transparent;
    position: absolute;
    color: white;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    padding-left: 5%;
    padding-top: 4%;
  }

  .ActividadPosition {
    top: 45vh;
    left: -2vw;
    position: absolute;
    position: absolute;
    overflow-y: scroll;
    border-radius: 10px;
    height: 21vh;
  }
}
</style>
