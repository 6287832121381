<template>
    <div class="fondotrans">
        <TransaccionComp>

        </TransaccionComp>
    </div>
</template>

<script>
import TransaccionComp from '@/components/TransaccionComp.vue';
export default {
    components: {
        TransaccionComp
    }
}

</script>

<style>
.fondotrans {
    background-image: url('@/assets/fondo.png');
    position: absolute;
    top: 11vh;
    left: 12vw;
    width: 88vw;
    height: 89vh;
    background-size: cover;
    margin: 0px;
    background-position: center;
}
</style>