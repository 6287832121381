<template>
  <div class="contPass">
    <div class="imagenPass">
      <div class="logo-containerPass">
        <img src="@/assets/candado.png" alt="Imagen" class="logoPass" />
      </div>
    </div>
    <div class="tituloPass">Cambia tu contraseña</div>

    <div>
      <input
        class="password1Pass"
        type="password"
        v-model="password1"
        placeholder="   Ingresá tu nueva contraseña"
      />
    </div>

    <div>
      <input
        class="password2Pass"
        type="password"
        v-model="password2"
        placeholder="   Confirma tu nueva contraseña"
      />
    </div>

    <div v-if="error" class="msjErrorPass">
        {{ errorMessage }}
    </div>

    <button class="buttonPass" @click="Guardar">Guardar</button>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import CryptoJS from "crypto-js";
export default {
  name: "PasswordComp",
  data() {
    return {
      password1: "",
      password2: "",
      errorMessage: "",
      error: false,
    };
  },
  methods: {
    Guardar() {
      if (this.password1.trim() === "" || this.password2.trim() === "") {
        this.setError(true, '¡Ups! Parece que uno o ambos campos están vacíos.');
        return;
      }
      if (this.password1 == this.password2) {
        const datos = {
          id: localStorage.getItem("id"),
          password: this.password1,
        };
        const token = localStorage.getItem("Token");
        const bytes = CryptoJS.AES.decrypt(token, this.$claveSecreta.value);
        const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);
        const config = {
          headers: {
            Authorization: `Bearer ${tokenDescifrado}`,
          },
        };
        axios
          .post(`${this.$ruta.value}/editPassword`, datos, config)
          .then(router.push("/profile"))
          .catch((error) => {
            console.error("Error al enviar el mensaje:", error);
          });
        this.error = false;
      } else {
        this.setError(true, '¡Ups! Parece que los campos no coinciden.');
      }
    },
    setError(value, message) {
      this.error = value;
      this.errorMessage = message;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .imagenPass {
    width: 20%;
    height: auto;
    top: 5%;
    left: 35%;
    position: absolute;
  }

  .logo-containerPass {
    width: 18vw;
    height: 10vh;
    position: absolute;
    margin-top: 15%;
    border-radius: 50%;
    background-color: #3d3d3d;
    left: 33%;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .contPass {
    width: 80%;
    height: 54%;
    top: 23vh;
    margin-left: 50%;
    transform: translateX(-50%);
    background-color: #171b1e;
    border-radius: 15px;
    border-color: transparent;
    position: absolute;
  }

  .msjErrorPass {
    font-family: "Poppins-Regular", Helvetica;
    font-size: 10px;
    color: #c61d1d;
    width: 70%;
    height: 8%;
    top: 76%;
    left: 50%;
    transform: translate(-49%, -10%);
    position: absolute;
  }

  .user-type-select,
  .password1Pass {
    width: 75%;
    height: 9%;
    top: 48%;
    left: 50%;
    transform: translate(-49%, -10%);
    position: absolute;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 15px;
    color: #ffffff;
    text-align: left;
    background-color: #3d3d3d;
    line-height: 20px;
    border-radius: 10px;
    box-shadow: none;
    border: 1px solid #3d3d3d;
  }

  .password2Pass {
    width: 75%;
    height: 9%;
    top: 63%;
    left: 50%;
    transform: translate(-49%, -10%);
    position: absolute;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 15px;
    color: #ffffff;
    background-color: #3d3d3d;
    text-align: left;
    line-height: 20px;
    border-radius: 10px;
    box-shadow: none;
    border: 1px solid #3d3d3d;
    margin-top: 1%;
  }

  .password1Pass::placeholder {
    color: #ffffff;
  }

  .password2Pass::placeholder {
    color: #ffffff;
  }

  .buttonPass {
    width: 77%;
    height: 9%;
    top: 84.5%;
    left: 50%;
    transform: translate(-49%, -10%);
    position: absolute;
    background-color: #41bf49;
    border-radius: 10px;
    text-align: center;
    color: white;
    font-family: "Poppins-SemiBold", Helvetica;
    font-weight: 600;
    font-size: 16px;
    border-color: transparent;
  }

  .tituloPass {
    width: 90%;
    height: 7%;
    top: 34%;
    left: 47.3%;
    transform: translate(-46%, -10%);
    position: absolute;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 20px;
    color: #41bf49;
    text-align: center;
    line-height: 20px;
    font-weight: 600;
  }
}
</style>
