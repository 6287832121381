<template>
    <div class="home">
        <barraUsuario></barraUsuario>
        <homeClientes></homeClientes>
        <barraClientes></barraClientes>


    </div>
</template>
  
<script>

import barraClientes from '@/components/BarraClientesComp.vue';
import barraUsuario from '@/components/BarraUsuarioComp.vue';
import homeClientes from '@/components/HomeClientesComp.vue';
export default {
    name: 'HomeView',
    components: {
        barraClientes,
        barraUsuario,
        homeClientes
    }
}

</script>

<style>
.home {
    background-image: url('@/assets/Login.png');
    width: 100vw;
    height: 100vh;
    background-size: cover;
    margin: 0px;
    background-position: center;
}
</style>