<template>
  <div class="fondologin">

    <Login />

  </div>
</template>

<script>
import Login from '@/components/Login.vue'
export default {
  components: {
    Login
  }
}
</script>

<style>
.fondologin {
  background-image: url('@/assets/Login.png');
  width: 100vw;
  height: 100vh;
  background-size: cover;
  margin: 0px;
  background-position: center;
}
</style>