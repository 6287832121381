<template>
    <div class="contCrear">
        <div class="ciravatar">
            <img class="avatar" src="@/assets/avatar.png">
        </div>
        <div class="creada">¡Cuenta creada con éxito!</div>
        <div class="textCrear">Ingrese sus datos para iniciar sesión en su nueva cuenta o visualizela en el apartado “Usuarios”.</div>
        <button @click="volver" class="volverIni">
            Volver al inicio
        </button>
    </div>
  </template>
  
  <script>
import router from '@/router';


  
  export default {
    name: "CrearComp",
    methods: {
        volver(){
            router.push("/inicio");
        }
    }
}
  </script>
  
  <style scoped>
  .contCrear {
    width: 35%;
    height: 75%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #171b1e;
    border-radius: 15px;
  }

  .ciravatar {
    height: 20vh;
    width: 10vw;
    position: absolute;
    top: 10%;
    border-radius: 50%;
    background-color: #2e2e2e;
    left: 33%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.volverIni {
    background-color: #41BF49;
    font-size: 20px;
    height: 6vh;
    width: 24vw;
    left: 11%;
    position: absolute;
    color: white;
    border-radius: 10px;
    padding-left: 3%;
    text-align: center;
    top: 81%;
    font-family: "Poppins-SemiBold", Helvetica;
    font-weight: 600;
    border-color: transparent;
}

.avatar {
    width: 90%;
    height: 90%;
    display: block;
    max-width: 100%;
    max-height: 100%;
   
    margin-top: -16%;
    margin-left: 9%;

}
.creada {
    color: #41bf49;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 28px;
    font-weight: 600;
    left: 17%;
    letter-spacing: 0;
    line-height: normal;
    position: fixed;
    text-align: center;
    top: 47%;

}

.textCrear {
    color: white;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    margin-top: 52%;
    padding: 10%;
    text-align: center;
}
</style>  