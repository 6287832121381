<template>
    <div class="fondoCuenta">
        <CrearComp>

        </CrearComp>
    </div>
</template>

<script>
import CrearComp from '@/components/CrearComp.vue';
export default {
    components: {
        CrearComp
    }
}

</script>

<style>
.fondoCuenta {
    background-image: url('@/assets/fondo.png');
    position: absolute;
    top: 11vh;
    left: 12vw;
    width: 88vw;
    height: 89vh;
    background-size: cover;
    margin: 0px;
    background-position: center;
}
</style>