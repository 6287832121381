<template>
    <div class="inicio">
        <img class="fondo" src="../assets/fondo.png">
        <saldo></saldo>
        <datos ></datos>
        <ActPersonal></ActPersonal>
    </div>
</template>
  
<script>
import Saldo from '@/components/SaldoComp.vue';
import Datos from '@/components/DatosComp.vue';
import ActPersonal from '@/components/ActperComp.vue';
export default {
 
    components: {
        Saldo,
        Datos,
        ActPersonal
    },
    
};


</script>

<style>
.inicio {
    height: 100vh;
    width: 100vw;
}


.inicio .fondo {
    position: absolute;
    top: 11vh;
    left: 12vw;
    width: 88vw;
    height: 89vh;

}

</style>
  