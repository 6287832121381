<template>
  <div class="contContra">
    <div class="imagenContra">
      <div class="logo-containerContra">
        <img src="@/assets/candado.png" alt="Imagen" class="logoContra" />
      </div>
    </div>
    <div class="tituloContra">Cambia tu contraseña</div>

    <div>
      <input
        class="password1Contra"
        type="password"
        v-model="password1"
        placeholder="   Ingresá tu nueva contraseña"
      />
    </div>

    <div>
      <input
        class="password2Contra"
        type="password"
        v-model="password2"
        placeholder="   Confirma tu nueva contraseña"
      />
    </div>

    <div v-if="error" class="msjError">
      {{ errorMessage }}
    </div>

    <button class="buttonContra" @click="Guardar">Guardar</button>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import CryptoJS from "crypto-js";
export default {
  name: "ContraComp",
  data() {
    return {
      password1: "",
      password2: "",
      errorMessage: "",
      error: false,
    };
  },
  methods: {
    Guardar() {
      if (this.password1.trim() === "" || this.password2.trim() === "") {
        this.setError(
          true,
          "¡Ups! Parece que uno o ambos campos están vacíos."
        );
        return;
      }
      if (this.password1 == this.password2) {
        const datos = {
          id: localStorage.getItem("id"),
          password: this.password1,
        };
        const token = localStorage.getItem("Token");
        const bytes = CryptoJS.AES.decrypt(token, this.$claveSecreta.value);
        const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);
        const config = {
          headers: {
            Authorization: `Bearer ${tokenDescifrado}`,
          },
        };
        axios
          .post(`${this.$ruta.value}/editPassword`, datos, config)
          .then(
            (this.$tipoOp.value = "cambio"),
            router.push("/operacionexitosa")
          )
          .catch((error) => {
            console.error("Error al enviar el mensaje:", error);
          });
        this.error = false;
      } else {
        this.setError(true, "¡Ups! Parece que los campos no coinciden.");
      }
    },
    setError(value, message) {
      this.error = value;
      this.errorMessage = message;
    },
  },
};
</script>

<style scoped>
.imagenContra {
  width: 20%;
  height: auto;
  top: 5%;
  left: 35%;
  position: absolute;
}

.logo-containerContra {
  width: 5vw;
  height: 10vh;
  position: absolute;
  margin-top: 20%;
  border-radius: 50%;
  background-color: #3d3d3d;
  left: 33%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.contContra {
  width: 30%;
  height: 65%;
  position: absolute;
  top: 52%;
  left: 51%;
  transform: translate(-50%, -50%);
  background-color: #171b1e;
  border-radius: 15px;
}

.msjError {
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  color: #c61d1d;
  width: 70%;
  height: 8%;
  top: 77%;
  left: 50%;
  transform: translate(-49%, -10%);
  position: absolute;
}

.user-type-select,
.password1Contra {
  width: 70%;
  height: 9%;
  top: 50%;
  left: 50%;
  transform: translate(-49%, -10%);
  position: absolute;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 15px;
  color: #ffffff;
  text-align: left;
  background-color: #3d3d3d;
  line-height: 20px;
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid #3d3d3d;
}

.password2Contra {
  width: 70%;
  height: 9%;
  top: 64%;
  left: 50%;
  transform: translate(-49%, -10%);
  position: absolute;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 15px;
  color: #ffffff;
  background-color: #3d3d3d;
  text-align: left;
  line-height: 20px;
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid #3d3d3d;
  margin-top: 1%;
}

.password1Contra::placeholder {
  color: #ffffff;
}

.password2Contra::placeholder {
  color: #ffffff;
}

.buttonContra {
  width: 73%;
  height: 9%;
  top: 85%;
  left: 50%;
  transform: translate(-49%, -10%);
  position: absolute;
  background-color: #41bf49;
  border-radius: 10px;
  text-align: center;
  color: white;
  font-family: "Poppins-SemiBold", Helvetica;
  font-weight: 600;
  font-size: 16px;
  border-color: transparent;
}

.tituloContra {
  width: 90%;
  height: 7%;
  top: 35%;
  left: 47.3%;
  transform: translate(-46%, -10%);
  position: absolute;
  font-family: "Poppins-SemiBold", Helvetica;
  font-size: 25px;
  color: #41bf49;
  text-align: center;
  line-height: 20px;
  font-weight: 600;
}
</style>
