<template>
    <div class="barraClientes">
        <div class="homeClientes">
            <router-link class="buttonClientes" to="/home" active-class="active-link">
                <span class="material-icons">home</span>
                <span class="textClientes">Inicio</span>
            </router-link>
        </div>
        <div class="ubiClientes">
            <router-link class="buttonClientes" to="/location" active-class="active-link">
                <span class="material-icons">location_on</span>
                <span class="textClientes">Ubicación</span>
            </router-link>
        </div>
        <div class="profileClientes">
            <router-link class="buttonClientes" to="/profile" active-class="active-link">
                <span class="material-icons">person</span>
                <span class="textClientes">Perfil</span>
            </router-link>
        </div>

    </div>
</template>
<script>
export default {
    name: 'BarraClientes',
};
</script>
<style>
@media screen and (max-width: 768px) {
    .barraClientes {
        width: 100%;
        height: 11%;
        background-color: #171b1e;
        border-color: transparent;
        position: relative;
        position: fixed;
        bottom: 0;
    }

    .homeClientes {
        position: absolute;
        top: 43%;
        transform: translateY(-50%);
        align-items: center;
        padding-top: 1vh;
        left: 19vw;
    }

    .ubiClientes {
        position: absolute;
        top: 43%;
        transform: translateY(-50%);
        align-items: center;
        padding-top: 1vh;
        left: 45vw;
    }

    .profileClientes {
        position: absolute;
        top: 43%;
        transform: translateY(-50%);
        align-items: center;
        padding-top: 1vh;
        left: 75vw;
    }

    .buttonClientes {
        color: white;
        font-family: "Poppins-Regular", Helvetica;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        position: relative;
        width: fit-content;
        text-decoration: none;
        border-bottom: none;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .material-icons {
        font-size: 30px;
    }

    .active-link {
        color: green;
    }

    .active-link .material-icons {
        color: green;
    }
}
</style>