<template>
  <div class="inicio">
    <img class="fondo" src="../assets/fondo.png" />

    <Registrar />

  </div>


</template>

<script>
import Registrar from '@/components/Registrar.vue'
export default {
    components: {
    Registrar
  },
};
</script>

<style>
.inicio {
  height: 300px;
  width: 300px;
}

.inicio .group {
  position: absolute;
  width: auto;
  height: auto;
}

.inicio .logo-grande {
  height: 500px;
  width: auto;
  top: 130px;
  left: 500px;
  position: absolute;
}

.inicio .fondo {
  position: absolute;
  top: 11vh;
  left: 12vw;
  width: 88vw;
  height: 89vh;
}

</style>
