<template>
    <div class="perfilCliente">
        <barraUsuario></barraUsuario>
        <perfiClientes></perfiClientes>
        <barraClientes></barraClientes>

    </div>
</template>
  
<script>

import barraClientes from '@/components/BarraClientesComp.vue';
import barraUsuario from '@/components/BarraUsuarioComp.vue';
import perfiClientes from '@/components/PerfilComp.vue';
import router from '@/router';
export default {
    name: 'PerfilClienteView',
    components: {
        barraClientes,
        barraUsuario,
        perfiClientes,
    },
    mounted() {
        if (localStorage.getItem('logged') !== 'true') {
            router.push('/login');
        }
    }
}

</script>

<style>
.perfilCliente {
    background-image: url('@/assets/Login.png');
    width: 100vw;
    height: 100vh;
    background-size: cover;
    margin: 0px;
    background-position: center;
}
</style>