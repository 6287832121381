<template>
  <div class="contError">
    <div class="textError">¡Error!</div>
    <div class="mensajeError">
      ¡Oh no! Nuestros servidores están siendo un poco tímidos ahora mismo.
      Estamos trabajando para que vuelvan a estar en línea pronto.
    </div>
    <div class="imgError">
      <span class="material-symbols-outlined"> error </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorComp",
};
</script>

<style scoped>
.contError {
  width: 45%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #171b1e;
  border-radius: 15px;
}

.textError {
  color: #ce3232;
  font-family: "Poppins-SemiBold", Helvetica;
  font-size: 30px;
  font-weight: 600;
  text-align: left;
  margin-left: 41%;
  margin-top: 12%;
  position: absolute;
}

.mensajeError {
  color: white;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  padding: 8%;
  margin-left: 33%;
  margin-top: 15%;
  position: absolute;
}

.imgError {
  color: #ce3232;
  gap: 50px;
  display: flex;
  align-items: center;
  position: absolute;
  margin-top: 12%;
  margin-left: 5%;
}

.material-symbols-outlined {
  font-size: 11em;
  margin-right: 2px;
}

@media screen and (max-width: 768px) {
  .contError {
    width: 80%;
    height: auto; /* Cambiado para adaptarse al contenido */
  }
.textError{
    margin-top: 5%; 
    margin-left: 40%; 
    text-align: center;
}

  .mensajeError
   {
    position: relative; 
    margin-left: 10%; 
    margin-top: 55%; 
    text-align: center;
  }
  .imgError {
    top: 10%;
    width: auto;
    left: 30%; 
  }

  .material-symbols-outlined {
    font-size: 7em; 
    margin-right: 2px;
  }
}
</style>
