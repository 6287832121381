<template>
  <div v-if="tamaño === 'grande'" class="contenedorAct">

    <div class="tipoAct" v-if="tipo === 'suma'">
      <img src="@/assets/billeteverde.png" alt="icon1">
    </div>
    <div class="tipoAct" v-else>
      <img src="@/assets/billeterojo.png" alt="icon2">
    </div>

    <div class="material">
      {{ descripcion }}
    </div>
    <div v-if="tipo === 'suma'" class="creditoAct">
      ${{ cantidad }}
    </div>
    <div v-else class="credito2">
      -${{ cantidad }}
    </div>
    <div>
      <div class="dateAct">
        {{ fecha }}
      </div>
    </div>
  </div>

  <div class="contenedorAct2" v-else>
    <div class="tipoAct" v-if="tipo === 'suma'">
      <img src="@/assets/billeteverde.png" alt="icon1">
    </div>
    <div class="tipoAct" v-else>
      <img src="@/assets/billeterojo.png" alt="icon2">
    </div>

    <div class="material">
      {{ descripcion }}
    </div>
    <div v-if="tipo === 'suma'" class="creditoAct">
      ${{ cantidad }}
    </div>
    <div v-else class="credito2">
      -${{ cantidad }}
    </div>
    <div>
      <div class="dateAct2">
        {{ fecha }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActividadComp',
  props: ['descripcion', 'cantidad', 'tipo', 'fecha', 'tamaño'],
}
</script>

<style>
.contenedorAct {
  height: 12vh;
  width: 52vw;
  background-color: #171B1E;
  position: relative;
  left: 4%;
  top: 10%;
  display: flex;
}

.contenedorAct2 {
  height: 12vh;
  width: 36vw;
  background-color: #171B1E;
  position: relative;
  left: 4%;
  top: 10%;
  display: flex;
}

.credito2 {
  left: 67%;
  width: 30%;
  height: 10%;
  justify-content: right;
  align-items: center;
  position: absolute;
  display: flex;
  font-size: 18px;
  font-family: "Poppins-Regular", Helvetica;
  color: red;
}

.tipoAct {
  height: 5.9vh;
  width: 4.2vw;
  position: absolute;
  top: 0%;
  border-radius: 50%;
  background-color: #2E2E2E;
}

.tipoAct img {
  width: 70%;
  height: 70%;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.creditoAct {
  left: 67%;
  width: 30%;
  height: 10%;
  justify-content: right;
  align-items: center;
  position: absolute;
  display: flex;
  font-size: 18px;
  color: rgba(13, 190, 28, 0.842);
  font-family: "Poppins-Regular", Helvetica;
}

.material {
  margin-left: 80px;
  font-size: 14px;
  color: white;
  font-family: "Poppins-Regular", Helvetica;

}

.dateAct {
  font-size: 14px;
  color: white;
  font-family: "Poppins-Regular", Helvetica;
  margin-top: 20px;
  left: 10%;
  position: absolute;
}

.dateAct2 {
  font-size: 14px;
  color: white;
  font-family: "Poppins-Regular", Helvetica;
  margin-top: 20px;
  left: 15%;
  position: absolute;
}
</style>