<template>
    <div class="navbar-vertical">

        <div class="inicio">
            <router-link class="button" to="/inicio" active-class="active-link">
                <span class="material-icons">home</span>
                <span class="text">Inicio</span>
            </router-link>
        </div>        

        <div class="perfil">
            <router-link class="button" to="/perfil" active-class="active-link">
                <span class="material-icons">account_circle</span>
                <span class="text">Mi perfil</span>
            </router-link>
        </div>

        <div class="salir">
            <router-link class="button" to="/cerrarsesion" active-class="active-link">
                <span class="material-icons">logout</span>
                <span class="text">Salir</span>
            </router-link>
        </div>

        <div v-if="this.$userType.value === 'Administrador' || this.$userType.value === 'Trabajador'" class="usuarios">
            <router-link class="button" to="/usuarios" active-class="active-link">
                <span class="material-icons">group</span>
                <span class="text">Clientes</span>
            </router-link>
        </div>

        <div v-if="this.$userType.value === 'Administrador' || this.$userType.value === 'Trabajador'" class="actividad">
            <router-link class="button" to="/actividad" active-class="active-link">
                <span class="material-icons">bar_chart</span>
                <span class="text">Actividad</span>
            </router-link>
        </div>

        <div v-if="this.$userType.value === 'Administrador'" class="trabajador">
            <router-link class="button" to="/trabajadores" active-class="active-link">
                <span class="material-icons"><span class="material-symbols-outlined">
                        manage_accounts
                    </span></span>
                <span class="text">Trabajadores</span>
            </router-link>
        </div>

    </div>
</template>
  
<script>

export default {
    name: 'NavbarVertical',
};
</script>
  
<style scoped>
.navbar-vertical {
    background-color: #171b1e;
    border: 1px solid;
    border-color: transparent;
    height: 100vh;
    position: relative;
    width: 13vw;
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    top: 9vh;
    left: 0;
    display: flex;
    flex-direction: column;
}

.inicio {
    align-items: center;
    gap: 24px;
    left: 0;
    padding: 20px 20px;
    position: absolute;
    top: 60px;
    width: 12vw;
}

.usuarios {
    align-items: center;
    display: flex;
    gap: 24px;
    left: 0;
    padding: 20px 20px;
    position: absolute;
    top: 125px;
    width: 12vw;
}

.trabajador {
    align-items: center;
    display: flex;
    gap: 24px;
    left: 0;
    padding: 20px 20px;
    position: absolute;
    top: 195px;
    width: 12vw;
}

.actividad {
    align-items: center;
    display: flex;
    gap: 24px;
    left: 0;
    padding: 20px 20px;
    position: absolute;
    top: 200px;
    width: 12vw;
}

.perfil {
    align-items: center;
    display: flex;
    gap: 24px;
    left: 0;
    padding: 20px 20px;
    position: absolute;
    top: 435px;
    width: 12vw;
}
.trabajador{
    align-items: center;
    display: flex;
    gap: 24px;
    left: 0;
    padding: 20px 20px;
    position: absolute;
    top: 260px;
    width: 12vw;
}

.salir {
    align-items: center;
    display: flex;
    gap: 24px;
    left: 0;
    padding: 20px 20px;
    position: absolute;
    top: 503px;
    width: 12vw;
}

.button {
    color: white;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
    text-decoration: none;
    border-bottom: none;
    display: flex;
    align-items: center;
}

.material-icons {
    letter-spacing: 10px;
}

.active-link {
    color: green;
}

.active-link .material-icons {
    color: green;
}
</style>