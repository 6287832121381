<template>
  <div>
    <ErrorComp v-if="mostrarError" />
    <div v-else>
      <div class="containerLogin">
        <div class="imagenLogin">
          <img src="@/assets/logoLogin.png" alt="Imagen logo" />
        </div>
        <div class="bienvenidoLogin">¡Bienvenido!</div>
        <div class="messageLogin">{{ msg }}</div>
        <div>
          <input class="phoneLogin" type="text" v-model="phone" placeholder="   Ingresá tu número de teléfono"
            maxlength="10" @input="PhoneInput" />
        </div>
        <div>
          <input class="passwordLogin" type="password" v-model="password" placeholder="   Ingresá tu contraseña" />
        </div>
        <button class="buttonLogin" @click="ingresar">Ingresar</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ErrorComp from "@/components/ErrorComp";
import CryptoJS from 'crypto-js';

export default {
  name: 'LoginComp',
  components: {
    ErrorComp,
  },

  data() {
    return {
      mostrarError: false,
      phone: '',
      password: '',
      msg: 'Ingresá tus datos para iniciar sesión en tu cuenta.',
      userData: '',
      claveSecreta: '123456789'
    };
  },

  mounted() {
    if (localStorage.getItem('logged') === 'true') {
      if (localStorage.getItem('userType') !== 'usuario') {
        this.$router.push('/inicio');
      } else {
        this.$router.push('/home');
      }
    }
  },

  methods: {
    PhoneInput() {
      this.phone = this.phone.replace(/\D/g, '');
    },

    ingresar() {
      axios.post(`${this.$ruta.value}/login`, {
        phone: this.phone,
        password: this.password
      })
        .then(response => {
          const encryptedToken = CryptoJS.AES.encrypt(
            response.data.accessToken,
            this.claveSecreta
          ).toString();
          this.$userType.value = response.data.user.user_type;
          localStorage.setItem('Token', encryptedToken);
          localStorage.setItem('userType', response.data.user.user_type);
          localStorage.setItem('id', response.data.user.id);
          localStorage.setItem('logged', true);

          if (response.data.user.user_type === 'usuario') {
            this.$router.push('/home');
          } else {
            this.$router.push('/inicio');
          }

        })
        .catch((error) => {
          console.error("Error al enviar el mensaje:", error);

          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            this.msg = "Credenciales inválidas.\nIntenta nuevamente.";
          } else {
            this.mostrarError = true;
          }
        });
    },


  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.containerLogin {
  width: 30%;
  height: 70%;
  position: absolute;
  top: 50.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #171b1e;
  border-radius: 15px;
}

.imagenLogin {
  width: 40%;
  height: 20%;
  top: 9%;
  left: 44%;
  transform: translate(-46%, -10%);
  position: absolute;
}

.bienvenidoLogin {
  width: 40%;
  height: 10%;
  top: 36%;
  left: 50%;
  transform: translate(-46%, -10%);
  position: absolute;
  font-family: "Poppins-SemiBold", Helvetica;
  font-size: 30px;
  color: #41bf49;
  text-align: center;
  line-height: 20px;
  font-weight: 600;
}

.messageLogin {
  width: 60%;
  height: 20%;
  top: 46%;
  left: 50%;
  transform: translate(-46%, -10%);
  position: absolute;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 20px;
  color: #d9d9d9;
  text-align: center;
  line-height: 20px;
}

.phoneLogin {
  width: 70%;
  height: 7%;
  top: 58%;
  left: 50%;
  transform: translate(-49%, -10%);
  position: absolute;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 15px;
  color: #ffffff;
  background-color: #3d3d3d;
  text-align: left;
  line-height: 20px;
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid #3d3d3d;
}

.passwordLogin {
  width: 70%;
  height: 7%;
  top: 70%;
  left: 50%;
  transform: translate(-49%, -10%);
  position: absolute;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 15px;
  color: #ffffff;
  background-color: #3d3d3d;
  text-align: left;
  line-height: 20px;
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid #3d3d3d;
}

.passwordLogin::placeholder {
  color: #ffffff;
}

.phoneLogin::placeholder {
  color: #ffffff;
}

.buttonLogin {
  width: 73%;
  height: 8%;
  top: 84%;
  left: 50%;
  transform: translate(-49%, -10%);
  position: absolute;
  background-color: #41bf49;
  border-radius: 10px;
  text-align: center;
  color: #fcfcfc;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 17px;
  border-color: transparent;
}

@media screen and (max-width: 768px) {
  .containerLogin {
    width: 80%;
    top: 50%;
    height: 80%;
    transform: translate(-50%, -50%);
  }

  .imagenLogin {
    width: auto;
    left: 50%;
  }

  .bienvenidoLogin,
  .messageLogin,
  .phoneLogin,
  .passwordLogin,
  .buttonLogin {
    width: 80%;
  }
}
</style>
