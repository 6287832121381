<template>
    <div class="fondoCerrar">
        <CerrarComp>

        </CerrarComp>
    </div>
</template>

<script>
import CerrarComp from '@/components/CerrarComp.vue';
import router from '@/router';
export default {
    components: {
        CerrarComp
    },
    mounted(){
        if (localStorage.getItem('logged') !== 'true') {
            router.push('/login');
        }
    }
}

</script>

<style>
.fondoCerrar {
    background-image: url('@/assets/fondo.png');
    position: absolute;
    top: 11vh;
    left: 12vw;
    width: 88vw;
    height: 89vh;
    background-size: cover;
    margin: 0px;
    background-position: center;
}
</style>