<template>
    <div class="perfilCliente">
        <barraUsuario></barraUsuario>
        <password></password>
        <barraClientes></barraClientes>

    </div>
</template>
  
<script>

import barraClientes from '@/components/BarraClientesComp.vue';
import barraUsuario from '@/components/BarraUsuarioComp.vue';
import password from '@/components/PasswordComp.vue';
export default {
    name: 'PerfilClienteView',
    components: {
        barraClientes,
        barraUsuario,
        password
    }
}

</script>

<style>
.perfilCliente {
    background-image: url('@/assets/Login.png');
    width: 100vw;
    height: 100vh;
    background-size: cover;
    margin: 0px;
    background-position: center;
}
</style>