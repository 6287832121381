<template>
  <ErrorComp v-if="mostrarError" />
  <div v-else>
    <div v-if="loading" class="spinner-container">
      <i class="fas fa-circle-notch fa-spin" style="font-size: 2em; color: #5e72e4; margin-right: 8px"></i>
      <Spinner :size="{ width: '4em', height: '4em' }" :color="'#5e72e4'" class="style-spinner" />
    </div>
    <div v-else>
      <div class="contProfile">
        <div class="profileImg">
          <input type="file" accept="image/*" id="img" style="display: none;" ref="img" @change="actImg" />
          <label for="img">
            <img class="photoProfile" :src="datosPersonales.image" />
          </label>

        </div>
        <div class="holaProfile">¡Hola {{ this.first_name }}!</div>
        <div class="userProfile">Usuario: {{ datosPersonales.user_type }}</div>
        <div class="nombreProfile">Nombre: {{ datosPersonales.full_name }}</div>
        <div class="phoneProfile">Teléfono: {{ datosPersonales.phone }}</div>
        <button @click="contraseña" class="cambiarpassword">
          Cambiar contraseña
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import ErrorComp from "@/components/ErrorComp";
import axios from "axios";
import CryptoJS from "crypto-js";
import { Spinner } from "vue-spinners";
export default {
  name: "ProfileComp",
  components: {
    Spinner,
    ErrorComp,
  },
  data() {
    return {
      loading: true,
      datosPersonales: "",
      mostrarError: false,
      img: null,
      id: localStorage.getItem("id"),
      token: localStorage.getItem("Token"),
      image: ''
    };
  },
  mounted() {
    const bytes = CryptoJS.AES.decrypt(this.token, this.$claveSecreta.value);
    const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);

    const config = {
      headers: {
        Authorization: `Bearer ${tokenDescifrado}`,
      },
    };

    axios
      .get(`${this.$ruta.value}/show/${this.id}`, config)
      .then((response) => {
        this.datosPersonales = response.data;
        this.first_name = this.datosPersonales.full_name.split(" ")[0];
      })
      .catch((error) => {
        // Manejar errores
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          console.error("Error al enviar el mensaje:", error);
        } else {
          this.mostrarError = true;
        }
      })

      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    contraseña() {
      router.push("/contrasena");
    },
    async actImg(event) {
      const file = event.target.files[0];
      if (file) {
        let formData = new FormData();
        formData.append('image', file);

        const bytes = CryptoJS.AES.decrypt(
          this.token,
          this.$claveSecreta.value
        );
        const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);
        const config = {
          headers: {
            Authorization: `Bearer ${tokenDescifrado}`,
          },
        };

        try {
          await axios.post(
            `${this.$ruta.value}/editImage/${this.id}`,
            formData, config)
        } catch (error) {
          console.log(error);
        }
        window.location.reload();
      }
    }
  },
};
</script>

<style scoped>
.contProfile {
  width: 35%;
  height: 75%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #171b1e;
  border-radius: 15px;
  align-items: center;
}

.userProfile {
  color: white;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 20%;
  letter-spacing: 0;
  line-height: normal;
  position: fixed;
  text-align: center;
  top: 51%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.nombreProfile {
  color: white;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 20%;
  letter-spacing: 0;
  line-height: normal;
  position: fixed;
  text-align: center;
  top: 60%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.phoneProfile {
  color: white;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 20%;
  letter-spacing: 0;
  line-height: normal;
  position: fixed;
  text-align: center;
  top: 70%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.passwordProfile {
  color: white;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 20%;
  letter-spacing: 0;
  line-height: normal;
  position: fixed;
  text-align: center;
  top: 72%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.cambiarpassword {
  background-color: #41bf49;
  font-size: 18px;
  height: 6vh;
  width: 18vw;
  left: 20%;
  position: absolute;
  color: white;
  border-radius: 10px;
  padding-left: 3%;
  text-align: center;
  top: 81%;
  font-family: "Poppins-SemiBold", Helvetica;
  font-weight: 600;
  border-color: transparent;
}

.photoProfile {
  height: 16vh;
  width: 8vw;
  position: absolute;
  top: 8%;
  border-radius: 50%;
  left: 38.5%;
  align-items: center;
  justify-content: center;
  display: flex;
  object-fit: cover;
}

.holaProfile {
  color: #41bf49;
  font-family: "Poppins-SemiBold", Helvetica;
  font-size: 27px;
  font-weight: 600;
  left: 34%;
  letter-spacing: 0;
  line-height: normal;
  position: fixed;
  text-align: center;
  top: 38%;
}

.textCrearProfile {
  color: white;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  margin-top: 52%;
  padding: 10%;
  text-align: center;
}

.style-spinner {
  width: 80px;
  height: 80px;
  border: 4px solid transparent;
  border-top: 8px solid #41bf49;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
