import { createApp, reactive } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'



const app = createApp(App).use(store).use(router);
app.config.globalProperties.$userType = reactive({ value: 'null' });
app.config.globalProperties.$claveSecreta = reactive({ value: '123456789' });

app.config.globalProperties.$idSelect = reactive({ value: 'null' });
app.config.globalProperties.$dueDateSelect = reactive({ value: 'null' });
app.config.globalProperties.$nameSelect = reactive({ value: 'null' });
app.config.globalProperties.$telefonoSelect = reactive({ value: 'null' });
app.config.globalProperties.$saldoSelect = reactive({ value: 'null' });
app.config.globalProperties.$imageSelect = reactive({ value: 'null' });
app.config.globalProperties.$tipoOp = reactive({ value: 'null' });
app.config.globalProperties.$ruta = reactive({ value: 'https://api-cb.agenciaponsi.com/api' });
app.mount('#app');