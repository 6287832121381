<template>
    <div class="fondoCambiar">
       <Contra></Contra>
    </div>
</template>

<script>
import Contra from '@/components/ContraComp.vue';
export default {
    components: {
       Contra
    }
}

</script>

<style>
.fondoCambiar {
    background-image: url('@/assets/fondo.png');
    position: absolute;
    top: 11vh;
    left: 12vw;
    width: 88vw;
    height: 89vh;
    background-size: cover;
    margin: 0px;
    background-position: center;
}
</style>