<template>
  <div class="navbar-horizontal">
    <img src="@/assets/LogoLargo.png" class="logo-largo" />
    <div v-if="loading" class="spinner-container">
      <Spinner :size="{ width: '4em', height: '4em' }" :color="'#5e72e4'" />
    </div>
    <div v-else>
      <img class="img-user" :src="datosPersonales.image" />
      <div class="text-user">{{ datosPersonales.full_name }}</div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import CryptoJS from 'crypto-js';
import { Spinner } from "vue-spinners";
export default {
  name: "NavbarHorizontal",
  components: {
    Spinner,
  },
  data() {
    return {
      loading: true,
      datosPersonales: "",
      token: localStorage.getItem("Token"),
      id: localStorage.getItem("id"),
    };
  },
  mounted() {
    const bytes = CryptoJS.AES.decrypt(this.token, this.$claveSecreta.value);
    const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);

    const config = {
      headers: {
        Authorization: `Bearer ${tokenDescifrado}`,
      },
    };

    axios
      .get(`${this.$ruta.value}/show/${this.id}`, config)
      .then((response) => {
        this.datosPersonales = response.data;
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          console.error("Error al enviar el mensaje:", error);
        } else {
          this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped>
.navbar-horizontal {
  background-color: #171b1e;
  height: 11vh;
  position: relative;
  width: 100vw;
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  display: flex;
}

.logo-largo {
  height: 60px;
  left: 20px;
  position: absolute;
  top: 10px;
  width: 170px;
}

.img-user {
  height: 50px;
  left: 95vw;
  position: absolute;
  top: 15px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.text-user {
  color: white;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  height: 34px;
  left: 86vw;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 30px;
  width: 132px;
}
</style>
