<template>
  <ErrorComp v-if="mostrarError" />
  <div v-else>
    <div class="encabezadoRetiro">
      <div class="imagenRetiro">
        <img class="billeterojo" src="@/assets/billeterojo.png" />
      </div>
      <div class="ingresaRetiro">Ingresá los datos</div>
    </div>

    <div class="datosRetiro">
      <div class="flechaRetiro" @click="volver">
        <span class="material-symbols-outlined"> arrow_back </span>
      </div>
      <div class="textoEntradaRetiro arribatextRetiro">Monto de la compra</div>
      <input v-model="monto" class="entradaRetiro arribaRetiro" type="text" @input="validarMonto"/>
      <div class="textoEntradaRetiro abajotextRetiro">Descripción</div>
      <input
        v-model="descripcion"
        class="entradaRetiro abajoRetiro"
        type="text"
      />
      <button @click="realizarRetiro" class="botonRetiro">
        Realizar retiro
      </button>
      <div class="fechaRetiro">Fecha: {{ fecha() }}</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import ErrorComp from "@/components/ErrorComp";
import CryptoJS from 'crypto-js';
export default {
  name: "RetiroComp",
  components: {
    ErrorComp,
  },
  data() {
    return {
      mostrarError: false,
      datosVenta: {
        description: "",
        user_id: "",
        amount: "",
        transaction_type: "resta",
        material: "",
      },
      monto:''
    };
  },
  methods: {
    volver() {
      this.$router.push("/personal");
    },
    validarMonto() {
      this.monto = this.monto.replace(/[^0-9.]/g, '');
      this.monto = this.monto.replace(/\.{2,}/g, '.');
      this.monto = this.monto.replace(/^\./g, '');
      if ((this.monto.match(/\./g) || []).length > 1) {
        const parts = this.monto.split('.');
        this.monto = parts.shift() + '.' + parts.join('');
      }
      if (parseFloat(this.monto) < 0) {
        this.monto = '0';
      }
    },
    realizarRetiro() {
      this.datosVenta.amount = this.monto;
      this.datosVenta.description = this.descripcion;
      this.datosVenta.user_id = this.$idSelect.value;

      const token = localStorage.getItem("Token");
      const bytes = CryptoJS.AES.decrypt(token, this.$claveSecreta.value);
      const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);
      //console.log(tokenDescifrado);
      const config = {
        headers: {
          Authorization: `Bearer ${tokenDescifrado}`,
        },
      };
      axios
        .post(`${this.$ruta.value}/transaction`, this.datosVenta, config)
        .then((response) => {
          if (response.data.estado === "Ok") {
            this.$tipoOp.value = "retiro";
            router.push("/operacionexitosa");
          } else {
            alert("Crédito insuficiente.\nNo se pudo realizar la transacción.");
            router.push("/personal");
          }
        })
        .catch((error) => {
          // Manejar errores
          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            console.error(error);
          } else {
            this.mostrarError = true;
          }
        });
    },
    fecha() {
      const fechaActual = new Date();
      const year = fechaActual.getFullYear();
      const month = (fechaActual.getMonth() + 1).toString().padStart(2, '0');
      const day = fechaActual.getDate().toString().padStart(2, '0');
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style>
.encabezadoRetiro {
  height: 12vh;
  width: 50vw;
  background-color: #171b1e;
  top: 7vh;
  left: 20vw;
  position: absolute;
  border-radius: 10px;
}

.ingresaRetiro {
  color: white;
  font-size: 30px;
  left: 9vw;
  position: absolute;
  top: 35%;
  font-family: "Poppins-Regular", Helvetica;
}

.imagenRetiro {
  height: 8vh;
  width: 4vw;
  position: absolute;
  top: 17%;
  border-radius: 50%;
  background-color: #2e2e2e;
  left: 6%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.billeterojo {
  width: 70%;
  height: 70%;
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.datosRetiro {
  height: 60vh;
  width: 50vw;
  background-color: #171b1e;
  top: 22vh;
  left: 20vw;
  position: absolute;
  border-radius: 10px;
}

.flechaRetiro {
  height: 20vh;
  width: 20vw;
  top: 4%;
  left: 6%;
  position: absolute;
  color: white;
}

.entradaRetiro {
  background-color: #171b1e;
  font-size: 20px;
  height: 6vh;
  width: 40vw;
  left: 6%;
  position: absolute;
  color: white;
  border-radius: 5px;
  padding-left: 3%;
  font-family: "Poppins-Regular", Helvetica;
  border-color: white;
}

.arribaRetiro {
  top: 28%;
}

.abajoRetiro {
  top: 57%;
}

.entrada:focus {
  outline: none;
}

.textoEntradaRetiro {
  height: 20%;
  left: 20%;
  width: 30%;
  transform: translate(-46%, -10%);
  position: absolute;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 20px;
  color: white;
  text-align: left;
  line-height: 20px;
}

.arribatextRetiro {
  top: 20%;
}

.abajotextRetiro {
  top: 49%;
}

.fechaRetiro {
  height: 20%;
  left: 17%;
  transform: translate(-46%, -10%);
  position: absolute;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 20px;
  color: white;
  text-align: right;
  line-height: 20px;
  top: 76%;
}

.botonRetiro {
  background-color: #ce3232;
  font-size: 19px;
  height: 6vh;
  width: 43vw;
  left: 6%;
  position: absolute;
  color: white;
  border-radius: 10px;
  padding-left: 3%;
  text-align: center;
  top: 83%;
  font-family: "Poppins-Regular", Helvetica;
  border-color: transparent;
}

.material-symbols-outlined {
  font-size: 35px;
}
</style>
