<template>
    <div class="contdatos">
        <div class="datosuser">Datos personales</div>
        <img class="userjpg" :src=this.$imageSelect.value>
        <div class="nombreuser">Nombre: {{ this.$nameSelect.value }}</div>
        <div class="telefonouser">Telefono: {{ this.$telefonoSelect.value }}</div>
    </div>
</template>

<script>
export default {
    name: 'DatosComp',
};
</script>

<style>
.contdatos {
    background-color: #171B1E;
    border-radius: 10px;
    height: 40vh;
    width: 35vw;
    top: 56%;
    left: 17%;
    overflow: hidden;
    position: absolute;
}

.datosuser {
    color: white;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 30px;
    font-weight: 400;
    left: 32px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 7%;

}

.nombreuser {
    color: white;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 32px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 60%;

}

.telefonouser {
    color: white;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 32px;
    letter-spacing: 0;
    position: absolute;
    top: 80%;

}

.userjpg{
    position: absolute;
    top: 22%;
    border-radius: 50%;
    object-fit: cover;
    left: 7%;
    width: 25%;
    height: 35%;
    
}
</style>