<template>
    <div class="fondoPerfil">
        <profile></profile>
    </div>
    
</template>

<script>
import Profile from '@/components/ProfileComp.vue';
export default {
    components: {
        Profile
    }
}

</script>

<style>
.fondoPerfil {
    background-image: url('@/assets/fondo.png');

    position: absolute;
    top: 11vh;
    left: 12vw;
    width: 88vw;
    height: 89vh;
    background-size: cover;
    margin: 0px;
    background-position: center;
}
</style>