<template>
  <div class="barraUsuario">
    <img src="@/assets/LogoLargo.png" class="logoClientes" />
    <div v-if="loading">
      <Spinner :size="{ width: '4em', height: '4em' }" :color="'#5e72e4'" />
    </div>
    <div v-else>
      <img class="imgCliente" :src="datosUser" />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import CryptoJS from "crypto-js";
import { Spinner } from "vue-spinners";

export default {
  name: "BarraUsuario",
  components: {
    Spinner,
  },
  data() {
    return {
      datosUser: "",
      id: localStorage.getItem("id"),
      loading: true,
    };
  },
  mounted() {
    const bytes = CryptoJS.AES.decrypt(
      localStorage.getItem("Token"),
      this.$claveSecreta.value
    );
    const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);

    const config = {
      headers: {
        Authorization: `Bearer ${tokenDescifrado}`,
      },
    };

    axios
      .get(`${this.$ruta.value}/show/${this.id}`, config)
      .then((response) => {
        this.datosUser = response.data.image;
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          console.error("Error al enviar el mensaje:", error);
        } else {
          this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style>
@media screen and (max-width: 768px) {
  .barraUsuario {
    width: 100%;
    height: 11%;
    margin-left: 50%;
    transform: translateX(-50%);
    background-color: #171b1e;
    border-color: transparent;
    position: absolute;
  }

  .logoClientes {
    height: 50px;
    left: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 130px;
  }

  .imgCliente {
    height: 50px;
    left: 82vw;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
}
</style>
