<template>
  <div id="app">
    <router-view></router-view>
    
    <div v-if="this.$userType.value==='Trabajador' || this.$userType.value==='Administrador'">
      <NavbarVertical ></NavbarVertical>
      <NavbarHorizontal></NavbarHorizontal>
    </div>
    
  </div>
</template>

<script>
import NavbarHorizontal from './components/NavbarHorizontal.vue';
import NavbarVertical from './components/NavbarVertical.vue'
import router from './router';

export default {
  data(){
    return{
      userType:'null',
      token:'null'
    }
  },
  components: {
    NavbarHorizontal,
    NavbarVertical,
  },
  mounted(){
    this.$userType.value=localStorage.getItem('userType');
      router.push('/login');
  }
};
</script>

<style>
#app {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>

