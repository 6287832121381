<template>
    <div class="contExit">
      <div class="cerrarExit">Cerrar Sesión</div>
      <div class="preguntaExit">¿Estás seguro de que deseas cerrar sesión?</div>
      <button @click="regresar" class="btnVolverExit">Volver</button>
      <button @click="salir" class="btnExit">Salir</button>
    </div>
  </template>
  
  <script>

  export default {
   
  };
  </script>
  
  <style>
@media screen and (max-width: 768px) {
  .contExit {
    width: 80vw;
    height: 35vh;
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #171b1e;
    border-radius: 10px;
  }
  
  .cerrarExit {
    color: white;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 17px;
    font-weight: 500;
    margin-top: 6%;
    margin-left: 33%;
  }
  
  .preguntaExit {
    color: white;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 22px;
    font-weight: 500;
    margin-top: 13%;
    padding-inline: 10%;
    text-align: center;
  }
  
  .btnVolverExit {
    background-color: #41bf49;
    border-radius: 20px;
    height: 6vh;
    overflow: hidden;
    position: absolute;
    width: 20vw;
    color: white;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    top: 68%;
    left: 20%;
    border-color: transparent;
  }
  
  .btnExit {
    background-color: #ce3232;
    border-radius: 20px;
    height: 6vh;
    overflow: hidden;
    position: absolute;
    width: 20vw;
    color: white;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    top: 68%;
    left: 54%;
    border-color: transparent;
  }
}
  </style>