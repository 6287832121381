<template>
    <div class="contActUser" >
        <div class="fechaUser" >
            {{ fecha }}
        </div>

        <div class="materialUser">
            {{ descripcion }}
        </div>
        <div v-if="tipo === 'suma'" class="creditoActUser">
            ${{ cantidad }}
        </div>
        <div v-else class="creditoActUser">
            -${{ cantidad }}
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'ActividadCliente',
    props: ['descripcion', 'cantidad', 'tipo', 'fecha'],
}
</script>
  
<style>
.contActUser {
    height: 7vh;
    width: 88vw;
    background-color: #171B1E;
    position: relative;
    display: flex;
}

.creditoActUser {
    left: 67%;
    width: 30%;
    justify-content: right;
    align-items: center;
    position: absolute;
    display: flex;
    font-size: 18px;
    font-family: "Poppins-Regular", Helvetica;
    margin-top: 23px;
    color: white;
}
.materialUser{
    font-size: 14px;
    color: white;
    font-family: "Poppins-Regular", Helvetica;
    margin-top: 19px;
    left: 30%;
    position: absolute;
}
.fechaUser {
    font-size: 14px;
    color: white;
    font-family: "Poppins-Regular", Helvetica;
    margin-top: 19px;
    left: 5%;
    position: absolute;
}
</style>