<template>
  <div class="fondoTrabajo">
    <div v-if="loading" class="spinner-container">
      <i class="fas fa-circle-notch fa-spin" style="font-size: 2em; color: #5e72e4; margin-right: 8px"></i>
      <Spinner :size="{ width: '4em', height: '4em' }" :color="'#5e72e4'" class="style-spinner" />
    </div>
    <div v-else>
      <ErrorComp v-if="mostrarError" />
      <div v-else>
        <div class="conTrabajo">
          <div class="tituloTrabajo">Trabajadores</div>
          <div class="aggTrabajador" @click="agregar">Agregar</div>
          <div>
            <trabajo v-for="user in users" :key="user.id" :id="user.id" :nombre="user.full_name" :imagen="user.image"></trabajo>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import ErrorComp from "@/components/ErrorComp";
import Trabajo from "@/components/TrabajoComp.vue";
import router from "@/router";
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  components: {
    Trabajo,
    ErrorComp,
    Spinner,
  },
  data() {
    return {
      mostrarError: false,
      users: "",
      loading: true
    };
  },
  mounted() {
    const token = localStorage.getItem("Token");
    const bytes = CryptoJS.AES.decrypt(token, this.$claveSecreta.value);
    const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);
    const config = {
      headers: {
        Authorization: `Bearer ${tokenDescifrado}`,
      },
    };
    axios
      .get(`${this.$ruta.value}/user`, config)
      .then((response) => {
        this.users = response.data;
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          console.error(error);
        } else {
          this.mostrarError = true;
        }
      }).finally(() => {
        this.loading = false;
      });
  },
  methods: {
    miFuncion(user) {
      this.$idSelect.value = user.id;
      this.$nameSelect.value = user.full_name;
      this.$telefonoSelect.value = user.phone;
      this.$imageSelect.value = user.image;
      this.$saldoSelect.value = user.account_amount;
      console.log(user.account_amount);
      router.push("/personal");
    },
    agregar() {
      router.push("/registrar");
    },
  },
};
</script>

<style>
.conTrabajo {
  height: 70vh;
  width: 56vw;
  position: absolute;
  background-color: #171b1e;
  left: 17vw;
  top: 10vh;
  overflow-y: scroll;
  border-radius: 10px;
}

.tituloTrabajo {
  height: 10vh;
  width: 50vw;
  font-size: 32px;
  color: #ffffff;
  position: relative;
  font-family: "Poppins-SemiBold", "Poppins", Helvetica;
  margin-left: 8%;
  margin-top: 4%;
}

.fondoTrabajo {
  background-image: url("@/assets/fondo.png");
  position: absolute;
  top: 11vh;
  left: 12vw;
  width: 88vw;
  height: 89vh;
  background-size: cover;
  margin: 0px;
  background-position: center;
}

.aggTrabajador {
  background-color: #41bf49;
  border-radius: 15px;
  height: 5vh;
  overflow: hidden;
  position: absolute;
  width: 8vw;
  color: #ffffff;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  top: 7%;
  left: 76%;
  border-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
