<template>
    <div class="fondoExito">
        <ExitoComp :mensaje="this.mensaje">

        </ExitoComp>
    </div>
</template>

<script>
import ExitoComp from '@/components/ExitoComp.vue';
export default {
    components: {
        ExitoComp
    },
    data() {
        return {
            mensaje: ''
        }
    },
    mounted() {
        if (this.$tipoOp.value == 'retiro') {
            this.mensaje = 'El retiro fué realizado con éxito';
        } else {
            if (this.$tipoOp.value == 'transaccion') {
                this.mensaje = 'El ingreso fué realizado con éxito';
            } else {
                if (this.$tipoOp.value=='delete') {
                    this.mensaje = 'El usuario fué eliminado con éxito';
                }else{
                    this.mensaje = 'La contraseña fue modificada con éxito';
                }
                
            }
        }
    }
}

</script>

<style>
.fondoExito {
    background-image: url('@/assets/fondo.png');
    position: absolute;
    top: 11vh;
    left: 12vw;
    width: 88vw;
    height: 89vh;
    background-size: cover;
    margin: 0px;
    background-position: center;
}
</style>