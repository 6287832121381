<template>
    <div class="inicioprincipal">
            <img class="fondoprincipal" src="../assets/fondo.png">
            <img class="logo-grandeprincipal" src="../assets/LogoGrande.png">
    </div>
</template>
  
<script>

export default {
    name: 'InicioView',
}

</script>

<style>
.inicioprincipal {
    height: 300px;
    width: 300px;
}

.inicioprincipal .groupprincipal {
    position: absolute;
    width: auto;
    height: auto;
   
}


.inicioprincipal .logo-grandeprincipal {
    height: 500px;
    width: auto;
    top: 175px;
    left: 600px;
    position: absolute;
}

.inicioprincipal .fondoprincipal {
    position: absolute;
    top: 11vh;
    left: 12vw;
    width: 88vw;
    height: 89vh;

}

</style>
  