<template>
    <div class="fondoEmer">
        <ErrorComp>

        </ErrorComp>
    </div>
</template>

<script>
import ErrorComp from '@/components/ErrorComp.vue';
export default {
    components: {
        ErrorComp
    }
}

</script>

<style>
.fondoEmer {
    background-image: url('@/assets/fondo.png');
    position: absolute;
    top: 11vh;
    left: 12vw;
    width: 88vw;
    height: 89vh;
    background-size: cover;
    margin: 0px;
    background-position: center;
}
</style>