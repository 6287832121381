<template>
    <div class="contenedoruser">
        <div class="image">
            <img :src=imagen alt="No se pudo cargar la imagen">
        </div>
        <div class="nombreuse">
            {{ nombre }}
        </div>
        <div class="credito">
            ${{ cantidad }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserComp',
    props: ['nombre', 'cantidad', 'imagen',]
}
</script>

<style>
.contenedoruser {
    height: 12vh;
    width: 52vw;
    background-color: #171B1E;
    position: relative;
    left: 4%;
    top: 10%;

}

.image {
    height: 10vh;
    width: 6.5vw;
    position: absolute;
    top: 10%;
    
    
}

.image img {
    width: 90%;
    height: 90%;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    object-fit: cover;
    border-radius: 50%;
}

.nombreuse {
    left: 18%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    display: flex;
    font-size: 25px;
    color: aliceblue;
    font-family: "Poppins-Regular", Helvetica;
}

.credito {
    left: 67%;
    width: 30%;
    height: 100%;
    justify-content: right;
    align-items: center;
    position: absolute;
    display: flex;
    font-size: 25px;
    color: aliceblue;
    font-family: "Poppins-SemiBold", Helvetica;
}
</style>