<template>
  <div class="ubicacion">
    <barraUsuario></barraUsuario>
    <div class="mapa">
      <div v-if="loading" class="spinner-container">
        <i class="fas fa-circle-notch fa-spin" style="font-size: 2em; color: #5e72e4; margin-right: 8px;"></i>
        <Spinner :size="{ width: '4em', height: '4em' }" :color="'#5e72e4'" class="style-spinner" />
      </div>

      <iframe
        id="mapa-style"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3699.7118772530007!2d-99.0122667!3d21.9840185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d673e9837a0f8d%3A0x77807690e5499844!2sImpresiones%20Ponsi!5e0!3m2!1ses-419!2smx!4v1704407447585!5m2!1ses-419!2smx"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        @load="onIframeLoad"
      ></iframe>
    </div>
    <barraClientes></barraClientes>
  </div>
</template>

<script>
import barraClientes from "@/components/BarraClientesComp.vue";
import barraUsuario from "@/components/BarraUsuarioComp.vue";
import { Spinner } from 'vue-spinners';

export default {
  name: "UbicacionView",
  components: {
    barraClientes,
    barraUsuario,
    Spinner,
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    onIframeLoad() {
      this.loading = false;
    },
  },
};
</script>




<style>
.ubicacion {
  background-image: url("@/assets/Login.png");
  width: 100vw;
  height: 100vh;
  background-size: cover;
  margin: 0px;
  background-position: center;
}

@media screen and (max-width: 768px) {
  .mapa {
    width: 85%;
    height: 64%;
    top: 18.5vh;
    margin-left: 50%;
    transform: translateX(-50%);
    background-color: #171b1e;
    border-radius: 15px;
    border-color: transparent;
    position: absolute;
  }

  #mapa-style {
    width: 90%;
    height: 90%;
    border: 0;
    border-radius: 15px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
