<template>
  <ErrorComp v-if="mostrarError" />
  <div v-else>
    <div class="encabezadotrans">
      <div class="tipoimagen">
        <img class="billeteverde" src="@/assets/billeteverde.png" />
      </div>
      <div class="encabText">Ingresá los datos</div>
    </div>

    <div class="datostrans">
      <div class="flecha" @click="volver">
        <span class="material-symbols-outlined"> arrow_back </span>
      </div>
      <select v-model="tipoSeleccionada" id="tipoVenta" class="selectTipo">
        <option v-for="tipo in tipoVenta" :key="tipo.id" :value="tipo.valor">
          {{ tipo.nombre }}
        </option>
      </select>
      <div class="textoEntrada arribatext">Monto de la compra</div>
      <input v-model="monto" class="entrada arriba" type="text" @input="validarMonto" />
      <div class="textoEntrada abajotext">Descripción</div>
      <input v-model="descripcion" class="entrada abajo" type="text" />
      <button class="botonTrans" @click="realizarTransaccion">
        Realizar ingreso
      </button>
      <div class="fecha">Fecha: {{fecha()}}</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import ErrorComp from "@/components/ErrorComp";
import CryptoJS from "crypto-js";
export default {
  name: "TransaccionComp",
  components: {
    ErrorComp,
  },
  data() {
    return {
      mostrarError: false,
      tipoVenta: [
        { nombre: "DTF Textil", id: "1", valor: "textil" },
        { nombre: "Lonas y vinil impreso", id: "2", valor: "lonas" },
        { nombre: "Tabloides", id: "3", valor: "tabloides" },
        { nombre: "Publicidad", id: "4", valor: "publicidad"},
        // Puedes agregar más tipos según sea necesario
      ],
      tipoSeleccionada: "",
      datosVenta: {
        description: "Lona de 30X15",
        user_id: "1",
        amount: "30000",
        transaction_type: "suma",
        material: "lonas",
      },
      
      monto:'',
     
    };
  },
  methods: {
    volver() {
      this.$router.push("/personal");
    },
    validarMonto() {
      this.monto = this.monto.replace(/[^0-9.]/g, '');
      this.monto = this.monto.replace(/\.{2,}/g, '.');
      this.monto = this.monto.replace(/^\./g, '');
      if ((this.monto.match(/\./g) || []).length > 1) {
        const parts = this.monto.split('.');
        this.monto = parts.shift() + '.' + parts.join('');
      }
      if (parseFloat(this.monto) < 0) {
        this.monto = '0';
      }
    },
    realizarTransaccion() {
      this.datosVenta.amount = this.monto;
      this.datosVenta.description = this.descripcion;
      this.datosVenta.user_id = this.$idSelect.value;
      this.datosVenta.material = this.tipoSeleccionada;
      

      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('Token'), this.$claveSecreta.value);
      const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);

      const config = {
        headers: {
          Authorization: `Bearer ${tokenDescifrado}`,
        },
      };
      axios
        .post(`${this.$ruta.value}/transaction`, this.datosVenta, config)
        .then((response) => {
          if (response.data.estado === "Ok") {
            this.$tipoOp.value = "transaccion";
          }
          router.push("/operacionexitosa");
        })
        .catch((error) => {
          // Manejar errores
          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            console.error(error);
          } else {
            this.mostrarError = true;
          }
        });
    },
    fecha() {
      const fechaActual = new Date();
      const year = fechaActual.getFullYear();
      const month = (fechaActual.getMonth() + 1).toString().padStart(2, '0');
      const day = fechaActual.getDate().toString().padStart(2, '0');
      return `${day}/${month}/${year}`;
    },

  },
};
</script>

<style>
.encabezadotrans {
  height: 12vh;
  width: 50vw;
  background-color: #171b1e;
  top: 6vh;
  left: 20vw;
  position: absolute;
  border-radius: 10px;
}

.encabText {
  color: white;
  font-size: 30px;
  left: 9vw;
  position: absolute;
  top: 35%;
  font-family: "Poppins-Regular", Helvetica;
}

.tipoimagen {
  height: 8vh;
  width: 4vw;
  position: absolute;
  top: 17%;
  border-radius: 50%;
  background-color: #2e2e2e;
  left: 6%;
}

.bilteverde {
  width: 100%;
  height: 100%;
  display: block;
}

.datostrans {
  height: 62vh;
  width: 50vw;
  background-color: #171b1e;
  top: 21vh;
  left: 20vw;
  position: absolute;
  border-radius: 10px;
}

.flecha {
  height: 20vh;
  width: 20vw;
  top: 4%;
  left: 6%;
  position: absolute;
  color: white;
}

.selectTipo {
  background-color: #171b1e;
  font-size: 20px;
  height: 6vh;
  width: 23vw;
  top: 15%;
  left: 6%;
  position: absolute;
  color: white;
  border-radius: 10px;
  padding-left: 3%;
  border-color: white;
  font-family: "Poppins-Regular", Helvetica;
}

.entrada {
  background-color: #171b1e;
  font-size: 20px;
  height: 6vh;
  width: 40vw;
  left: 6%;
  position: absolute;
  color: white;
  border-radius: 5px;
  padding-left: 3%;
  font-family: "Poppins-Regular", Helvetica;
  border-color: white;
}

.arriba {
  top: 37%;
}

.abajo {
  top: 60%;
}

.entrada:focus {
  outline: none;
}

.textoEntrada {
  height: 20%;
  left: 20%;
  width: 30%;
  transform: translate(-46%, -10%);
  position: absolute;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 20px;
  color: white;
  text-align: left;
  line-height: 20px;
}

.arribatext {
  top: 31%;
}

.abajotext {
  top: 55%;
}

.fecha {
  height: 20%;
  left: 17%;
  transform: translate(-46%, -10%);
  position: absolute;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 20px;
  color: white;
  text-align: right;
  line-height: 20px;
  top: 76.5%;
}

.botonTrans {
  background-color: #41bf49;
  font-size: 19px;
  height: 6vh;
  width: 43vw;
  left: 6%;
  position: absolute;
  color: white;
  border-radius: 10px;
  padding-left: 3%;
  text-align: center;
  top: 83%;
  font-family: "Poppins-Regular", Helvetica;
  border-color: transparent;
}

.material-symbols-outlined {
  font-size: 35px;
}
</style>
