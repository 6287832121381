import { createRouter, createWebHistory } from 'vue-router'
import InicioView from '../views/InicioView.vue'
import LoginView from '../views/LoginView.vue'
import UsuariosView from '../views/UsuariosView.vue'
import ActividadView from '../views/ActividadView.vue'

import NuevaTransaccion from '../views/NuevaTransaccion.vue'
import RegisterView from '../views/RegisterView.vue'
import PersonalView from '../views/PersonalView.vue'
import HomeView from '../views/HomeView.vue'
import NuevoRetiro from '../views/NuevoRetiro.vue'
import OperacionExitosa from '../views/OperacionExitosa.vue'
import CerrarSesion from '../views/CerrarSesion.vue'
import CuentaView from '../views/CuentaView.vue'
import TrabajadoresView from '../views/TrabajadoresView.vue'
import PerfilView from '../views/PerfilView.vue'
import EmergenteView from '../views/EmergenteView.vue'
import CambiarContraView from '../views/CambiarContraView.vue'
import locationView from '../views/UbicacionView.vue'
import PerfilClienteView from '../views/PerfilClienteView.vue'
import CambiarContraUserView from '../views/CambiarContraUser.vue'
import ExitSesion from '../views/ExitSesionView.vue'

const routes = [
  {
    path: '/inicio',
    name: 'inicio',
    component: InicioView
  },
  {
    path: '/',
    name: 'home',
    component: LoginView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: UsuariosView
  },
  {
    path: '/actividad',
    name: 'actividad',
    component: ActividadView
  },

  {
    path: '/nuevatransaccion',
    name: 'nuevatransaccion',
    component: NuevaTransaccion
  },

  {
    path: '/registrar',
    name: 'registrar',
    component: RegisterView
  },
  {
    path: '/personal',
    name: 'personal',
    component: PersonalView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/nuevoretiro',
    name: 'nuevoretiro',
    component: NuevoRetiro
  },
  {
    path: '/operacionexitosa',
    name: 'operacionexitosa',
    component: OperacionExitosa
  },
  {
    path: '/cerrarsesion',
    name: 'cerrarsesion',
    component: CerrarSesion
  },
  {
    path: '/crearcuenta',
    name: 'crearcuenta',
    component: CuentaView
  },
  {
    path: '/trabajadores',
    name: 'trabajadores',
    component: TrabajadoresView
  }, 
  {
    path: '/perfil',
    name: 'perfil',
    component: PerfilView
  },
  {
    path: '/error',
    name: 'error',
    component: EmergenteView
  },
  {
    path: '/contrasena',
    name: 'contrasena',
    component: CambiarContraView
  },
  {
    path: '/location',
    name: 'location',
    component: locationView
  },
  {
    path: '/profile',
    name: 'profile',
    component: PerfilClienteView
  },
  {
    path: '/contrasenaUser',
    name: 'contrasenaUser',
    component: CambiarContraUserView
  },
  {
    path: '/exit',
    name: 'exit',
    component: ExitSesion
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
