<template>
  <ErrorComp v-if="mostrarError" />
  <div v-else>
    <div v-if="loading" class="spinner-container">
      <i class="fas fa-circle-notch fa-spin" style="font-size: 2em; color: #5e72e4; margin-right: 8px"></i>
      <Spinner :size="{ width: '4em', height: '4em' }" :color="'#5e72e4'" class="style-spinner" />
    </div>
    <div v-else>
      <div class="ventemerg">

        <div class="encabezado">
          <div class="nombre">{{ nombre }}</div>
        </div>
        <div class="cantidadTrans">Monto: ${{ cantidad }}</div>

        <div class="descripcionTrans">Descripción: {{ descripcion }}</div>

        <div class="fechaTrans">Fecha: {{ fecha }}</div>


        <div class="tipo1" v-if="tipo === 'suma'">
          <div class="boton" @click="cerrarVent()">Volver</div>
        </div>
        <div class="tipo2" v-else>
          <div class="boton2" @click="cerrarVent()">Volver</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorComp from "@/components/ErrorComp";
import axios from "axios";
import CryptoJS from "crypto-js";
import { Spinner } from "vue-spinners";
export default {
  name: 'TransferenciaComp.vue',
  props: ['fecha', 'cantidad', 'descripcion', 'tipo'],
  components: {
    Spinner,
    ErrorComp,
  },
  data() {
    return {
      nombre: '',
      mostrarError: false,
      loading: true,
      token: localStorage.getItem('Token'),
    }
  },
  mounted() {
    const bytes = CryptoJS.AES.decrypt(this.token, this.$claveSecreta.value);
    const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);

    const config = {
      headers: {
        Authorization: `Bearer ${tokenDescifrado}`,
      },
    };

    axios
      .get(`${this.$ruta.value}/show/${this.$idSelect.value}`, config)
      .then((response) => {
        console.log(response.data);
        this.nombre = response.data.full_name;
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          console.error("Error al enviar el mensaje:", error);
        } else {
          this.mostrarError = true;
        }
      })

      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    cerrarVent() {
      this.$emit('cerrarVent');
    },

  }
}
</script>

<style>
.ventemerg {
  background-color: #171B1E;
  width: 25vw;
  height: 30vh;
  padding: 2%;
  position: fixed;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: .5px solid #cccccc;
}

.nombre {
  font-size: 29px;
  color: aliceblue;
  top: 5%;
  left: 30%;
  position: absolute;
  font-family: "Poppins-Regular", Helvetica;
}

.img {
  top: 8%;
  right: auto;
}

.encabezado {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  font-family: "Poppins-Regular", Helvetica;
}

.nombre {
  font-weight: bold;
  font-size: 15px;
  font-family: "Poppins-Regular", Helvetica;
}

.cantidadTrans {
  color: aliceblue;
  font-size: 20px;
  margin-bottom: 20px;
  font-family: "Poppins-Regular", Helvetica;

}

.descripcionTrans {
  color: aliceblue;
  font-size: 20px;
  margin-bottom: 20px;
  font-family: "Poppins-Regular", Helvetica;
}

.fechaTrans {
  color: aliceblue;
  font-size: 20px;
  top: 65%;
  position: absolute;
  font-family: "Poppins-Regular", Helvetica;
}

.tipo1 {
  background-color: #4CAF50;
  color:white;
  padding: 10px;
  border: none;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 18px;
  width: 78%;
  cursor: pointer;
  top: 80%;
  position: fixed;
  border-radius: 10px;
  font-family: "Poppins-Regular", Helvetica;
  font-weight: 600;
}

.tipo2 {
  background-color: #e40909;
  color:white;
  padding: 10px;
  border: none;
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  display: block;
  font-size: 18px;
  width: 78%;
  cursor: pointer;
  top: 80%;
  position: fixed;
  font-family: "Poppins-Regular", Helvetica;
  font-weight: 600;
}
</style>