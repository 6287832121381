<template>
  <div class="fondoAct">
  <ErrorComp v-if="mostrarError" />
  <div v-else>
    <div v-if="loading" class="spinner-container">
        <i
          class="fas fa-circle-notch fa-spin"
          style="font-size: 2em; color: #5e72e4; margin-right: 8px"
        ></i>
        <Spinner
          :size="{ width: '4em', height: '4em' }"
          :color="'#5e72e4'"
          class="style-spinner"
        />
      </div>
      <div v-else>
    <div class="contAct">
      <div class="tituloAct">Actividad</div>
      <div>
        <Actividad
          v-for="actividad in actividades"
          :key="actividad.id"
          :descripcion="actividad.description"
          :cantidad="actividad.amount"
          :tipo="actividad.transaction_type"
          :fecha="actividad.creation_date"
          :tamaño="tamaño"
          @click="mostrarVentEmer(actividad)"
        >
        </Actividad>
      </div>
      <Transferencia
        v-if="mostrarVent"
        :cantidad="cantidadSeleccionada"
        :descripcion="descripcionSeleccionado"
        :tipo="tipoSeleccionado"
        :fecha="fechaSeleccionada"
        @cerrarVent="cerrarVent"
      ></Transferencia>
    </div>
  </div>
  </div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js"; 
import Actividad from "@/components/ActividadComp.vue";
import Transferencia from "@/components/TransferenciaComp.vue";
import ErrorComp from "@/components/ErrorComp";
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  components: {
    Actividad,
    Transferencia,
    ErrorComp,
    Spinner
  },
  data() {
    return {
      loading: true,
      mostrarError: false,
      mostrarVent: false,
      cantidadSeleccionada: null,
      materialSeleccionado: null,
      tipoSeleccionado: null,
      fechaSeleccionada: null,
      actividades: "",
      tamaño: "grande",
    };
  },
  methods: {
    mostrarVentEmer({ description, amount, transaction_type, creation_date, user_id }) {
      this.descripcionSeleccionado = description;
      this.cantidadSeleccionada = amount;
      this.tipoSeleccionado = transaction_type;
      this.fechaSeleccionada = creation_date;
      this.$idSelect.value=user_id;
      this.mostrarVent = true;
    },
    cerrarVent() {
      this.mostrarVent = false;
    },
  },
  mounted() {
    const token = localStorage.getItem("Token"); 
    const bytes = CryptoJS.AES.decrypt(token, this.$claveSecreta.value);
    const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);
    const config = {
      headers: {
        Authorization: `Bearer ${tokenDescifrado}`,
      },
    };
    axios
      .get(`${this.$ruta.value}/transaction`, config)
      .then((response) => {
        this.actividades = response.data;
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
            console.error(error);
        } else {
          this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style>
.cantidad {
  margin-top: 20px;
  font-size: 25px;
}

.contAct {
  height: 70vh;
  width: 56vw;
  position: absolute;
  background-color: #171b1e;
  left: 17vw;
  top: 10vh;
  overflow-y: scroll;
  border-radius: 10px;
}

.tituloAct {
  height: 10vh;
  width: 50vw;
  font-size: 32px;
  color: #ffffff;
  position: relative;
  font-family: "Poppins-SemiBold", Helvetica;
  margin-left: 8%;
  margin-top: 4%;
}

.fondoAct {
  background-image: url("@/assets/fondo.png");
  position: absolute;
  top: 11vh;
  left: 12vw;
  width: 88vw;
  height: 89vh;
  background-size: cover;
  margin: 0px;
  background-position: center;
}
</style>
