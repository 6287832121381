<template>
    <ErrorComp v-if="mostrarError" />
    <div v-else>
        <div class="contenedorTra">
            <div class="imageTra">
                <img :src="imagen" alt="No se pudo cargar la imagen">
            </div>
            <div class="nombreTra">
                {{ nombre }}
            </div>
            <div v-if="userType === 'Administrador' && id !== 1">
                <div @click="eliminar" class="eliminar">
                    Eliminar
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CryptoJS from "crypto-js";
import ErrorComp from "@/components/ErrorComp";
import axios from "axios";
import router from "@/router";
export default {
    name: 'TrabajoComp',
    props: ['nombre', 'imagen', 'id'],
    data() {
        return {
            userType: localStorage.getItem('userType'),
            mostrarError: false,
            eliminada: false
        }
    },
    components: {
        ErrorComp,
    },
    methods: {
        eliminar() {
            const token = localStorage.getItem("Token");
            const bytes = CryptoJS.AES.decrypt(token, this.$claveSecreta.value);
            const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);
            const config = {
                headers: {
                    Authorization: `Bearer ${tokenDescifrado}`,
                },
            };

            console.log(this.id);
            axios
                .delete(`${this.$ruta.value}/editDelete/${this.id}`, config)
                .then(
                    this.$tipoOp.value = 'delete',
                    router.push('/operacionexitosa'))
                .catch((error) => {
                    if (
                        error.response &&
                        (error.response.status === 401 || error.response.status === 422)
                    ) {
                        console.error(error);
                    } else {
                        this.mostrarError = true;
                    }
                });
        }
    }

}
</script>

<style>
.contenedorTra {
    height: 12vh;
    width: 52vw;
    background-color: #171B1E;
    position: relative;
    left: 4%;
    top: 10%;

}

.imageTra {
    height: 10vh;
    width: 6.5vw;
    position: absolute;
    top: 10%;
    left: 2%;
}

.imageTra img {
    width: 90%;
    height: 90%;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    object-fit: cover;
    border-radius: 50%;
    
}

.nombreTra {
    left: 18%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    display: flex;
    font-size: 25px;
    color: aliceblue;
    font-family: "Poppins-Regular", Helvetica;
}

.eliminar {
    background-color: red;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    top: 20%;
    height: 60%;
    width: 15%;
    left: 80%;
    position: absolute;
    color: #ffffff;
    font-family: "Poppins-Medium", Helvetica;
    border-color: transparent;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
}
</style>