<template>
  <ErrorComp v-if="mostrarError" />
  <div v-else>
    <div class="act">
      <div class="actividad">Actividad</div>
      <div class="transPer">
        <Actividad
          v-for="actividad in actividades"
          :key="actividad.id"
          :descripcion="actividad.description"
          :cantidad="actividad.amount"
          :tipo="actividad.transaction_type"
          :fecha="actividad.creation_date"
        >
        </Actividad>
      </div>
    </div>
  </div>
</template>

<script>
import Actividad from "@/components/ActividadComp.vue";
import ErrorComp from "@/components/ErrorComp";
import axios from "axios";
import CryptoJS from "crypto-js"; 
export default {
  name: "ActperComp",
  components: {
    Actividad,
    ErrorComp,
  },
  data() {
    return {
      mostrarError: false,
      actividades: "",
    };
  },
  mounted() {
    const token = localStorage.getItem("Token"); 
    const bytes = CryptoJS.AES.decrypt(token, this.$claveSecreta.value);
    const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);
    const config = {
      headers: {
        Authorization: `Bearer ${tokenDescifrado}`,
      },
    };
    const id = this.$idSelect.value;
    axios
      .get(`${this.$ruta.value}/transaction/${id}`, config)
      .then((response) => {
        this.actividades = response.data;
      })
      .catch((error) => {
        // Manejar errores
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          console.error(error);
        } else {
          this.mostrarError = true;
        }
      });
  },
};
</script>

<style>
.act {
  background-color: #171b1e;
  border-radius: 10px;
  height: 81vh;
  width: 38vw;
  top: 15%;
  left: 57%;
  position: absolute;
  overflow: hidden;
}

.actividad {
  color: white;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 30px;
}
.transPer {
  position: absolute;
  top: 90px;
}
</style>
