<template>
  <div class="contCerrar">
    <div @click="regresar" class="flechaCerrar">
      <span class="material-symbols-outlined"> arrow_back </span>
    </div>
    <div class="cerrar">Cerrar Sesión</div>
    <div class="pregunta">¿Estás seguro de que deseas cerrar sesión?</div>
    <button @click="regresar" class="btnVolver">Volver</button>
    <button @click="salir" class="btnSalir">Salir</button>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import CryptoJS from "crypto-js"; 
export default {
  methods: {
    salir() {
      const token = localStorage.getItem("Token");
      const bytes = CryptoJS.AES.decrypt(token, this.$claveSecreta.value);
      const tokenDescifrado = bytes.toString(CryptoJS.enc.Utf8);
      //console.log(tokenDescifrado);
      const config = {
        headers: {
          Authorization: `Bearer ${tokenDescifrado}`,
        },
      };
      axios
        .get(`${this.$ruta.value}/logout`, config)
        .then((response) => {
          console.log(response.data);
          localStorage.setItem("Token", null);
          localStorage.setItem("logged", false);
          localStorage.setItem("id", null);
          localStorage.setItem("userType", null);
          this.$userType.value = "null";
          router.push("/login");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    regresar() {
      router.push("/inicio");
    },
  },
};
</script>

<style>
.contCerrar {
  width: 40vw;
  height: 55vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #171b1e;
  border-radius: 10px;
}

.flechaCerrar {
  height: 20vh;
  width: 20vw;
  top: 4%;
  left: 6%;
  position: absolute;
  color: white;
}

.cerrar {
  color: white;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  margin-top: 4%;
  margin-left: 40%;
}

.pregunta {
  color: white;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 40px;
  font-weight: 500;
  margin-top: 15%;
  padding-inline: 10%;
  text-align: center;
}

.btnVolver {
  background-color: #41bf49;
  border-radius: 20px;
  height: 6vh;
  overflow: hidden;
  position: absolute;
  width: 10vw;
  color: white;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  top: 70%;
  left: 20%;
  border-color: transparent;
}

.btnSalir {
  background-color: #ce3232;
  border-radius: 20px;
  height: 6vh;
  overflow: hidden;
  position: absolute;
  width: 10vw;
  color: white;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  top: 70%;
  left: 54%;
  border-color: transparent;
}
</style>
